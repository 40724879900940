<template>
  <div class="base-recommendations-container" v-if="resources">
    <div class="base-recommendations-max-width" :id="getId(itemList.index)">
      <div class="base-recommendations-title-box">
        <h1 class="base-recommendations-title">{{ $rt(title).toUpperCase() }}</h1>
        <hr class="base-recommendations-hr" />
      </div>
      <div ref="baseLayout" class="base-recommendations-layout" v-if="resources.length > 0">
        <LocLink class="base-recommendations-box" v-for="(prod, index) in resources" @mouseover="handleHover(index)"
          @mouseleave="handleLeave(index)" :to="this.getProductLink(prod)">
          <div class="base-recommendations-image-box">
            <img class="base-recommendations-image" :src="this.getProductImg(prod.productURL)" />
          </div>
          <div class="base-recommendations-content">
            <h4 class="base-recommendations-item-title">
              {{ $rt(prod.productName) }}
            </h4>
            <div class="base-recommendations-summary" v-html="$rt(prod.productSummary)">
            </div>
            <div>
              <BasePerformance :performanceData="this.getPerfData(prod)" size="small" />
            </div>
          </div>
          <div class="base-recommendations-view-container">
            <div class="base-recommendations-view-box">
              <h4 class="base-view-product-text">
                {{ this.toUpperCase($t('viewProduct')) }}
              </h4>
              <BaseArrow :ref="'arrow' + index" color="#000" hover-color="orange" />
            </div>
          </div>
        </LocLink>
      </div>
      <div class="br-footer">
        <div class="br-next" @click="handleNext">NEXT</div>
        <BaseArrow class="br-arrow" color="rgb(147, 147, 147)" @click="handleNext" />
      </div>
    </div>
  </div>
</template>

<script>

import { usePillarStore } from '@/store/PillarStore';
import LocLink from './LocLink.vue';
import BaseArrow from '~/components/base/BaseArrow.vue';
import BasePerformance from './BasePerformance.vue';
import { usePerformanceStore } from '~/store/PerformanceStore';

export default {
  setup() {
    const pillarStore = usePillarStore();
    return {
      pillarStore,
    };
  },
  components: {
    LocLink,
    BaseArrow,
    BasePerformance
  },
  props: [
    'items',
  ],
  data() {
    return {
      productItems: [],
      pageProducts: [],
      prodFamlies: null,
      familyUrl: '',
      nelsonUrl: 'https://nelsonirrigation.com',
    };
  },
  mounted() {
    this.productItems = this.$props.items;
    var router = useRoute();
    this.familyUrl = router.path;//family url as well as selected group
  },
  computed: {
    resources() {
      if (this.productItems.items && this.productItems.items.length > 0)
        return this.productItems.items;
      else return [];
    },
    title() {
      if (this.productItems.items && this.productItems.items.length > 0)
        return this.productItems.title;
      else return '';
    },
    itemList() {
      return this.productItems
    },
  },
  methods: {
    getProductLink(prod) {
      var prodFamilies = this.$tm('productFamilies');
      const currProductFam = prodFamilies.find((element) => element.pfID === prod.pfID_fk);
      let url = '';
      if (currProductFam)
        url = '/products/' + this.$rt(currProductFam.productFamilyURL) + '/' + this.$rt(prod.productURL);
      return url;
    },
    getId(index) {
      if (typeof this.productItems.index === 'object') {
        return this.$rt(this.productItems.index);
      } else {
        return this.productItems.index;
      }
    },
    getProductImg(productURL) {
      let img = '/images/products/' + this.$rt(productURL) + '-hero.webp';
      return img;
    },
    async getPerfData(product) {
      const performanceStore = usePerformanceStore();
      const perfData = await performanceStore.getAllPerformanceData(product.pID);
      return perfData;
    },

    checkImageExists(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
        img.src = url;
      });
    },
    handleHover(index) {
      this.$refs[('arrow' + index)][0].handleOver();
    },
    handleLeave(index) {
      this.$refs[('arrow' + index)][0].handleOut();
    },
    toUpperCase(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    },
    handleNext() {

      let child = this.$refs.baseLayout.children[0];
      let gap = getComputedStyle(this.$refs.baseLayout).gap;
      this.$refs.baseLayout.scrollLeft += (child.getBoundingClientRect().width + parseInt(gap));
    }
  },
};
</script>

<style>
.base-recommendations-summary p {
  font-size: 15px !important;
  margin: 0;
  padding: 0;
}
</style>

<style scoped>
.base-view-product-text {
  font-size: 13px;
}

.base-recommendations-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f1;
  cursor: pointer;
  width: 100%;
  padding: 40px;
}

.base-recommendations-max-width {
  width: 100%;
  max-width: 1200px;
}

.base-recommendations-layout {
  display: grid;
  max-width: 1200px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
}

.base-recommendations-box:visited,
.base-recommendations-box:link {
  color: black;
}

.base-recommendations-box {
  border: 1px solid #e7e7e7;
  text-decoration: none;
  color: black;
  background-color: white;
  display: grid;
  /*  height: 500px;*/
  grid-template-rows: 1fr 220px 60px;
}

.base-recommendations-image-box {
  justify-self: center;
  width: 250px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.base-recommendations-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.base-recommendations-content {
  font-size: 1.35rem;
  border-top: 1px solid #D3CDC2;
  margin-left: 15px;
  margin-right: 15px;
  grid-row: 2/3;
}

.base-recommendations-view-container {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.base-recommendations-view-box {
  border-top: 1px #D3CDC2 solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
}

.base-recommendations-title {
  font-weight: 900;
  font-size: 16px;
}

.base-recommendations-title-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 25px 0;
  width: 100%;
  max-width: 1200px;
}

.base-recommendations-summary {
  font-weight: normal;
  line-height: 1.4em;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 10px;
}

.base-recommendations-item-title {
  font-size: 17px;
  line-height: 1.3em;
  margin: 10px 0;
}

.base-recommendations-hr {
  color: #D3CDC2;
  fill: #D3CDC2;
  stroke: #D3CDC2;
  background-color: #D3CDC2;
  width: 100%;
  max-width: 1200px;
}

.br-footer {
  display: none;
  justify-content: flex-end;
  margin-top: 10px;

}

.br-arrow {
  scale: .7;
  transform: translateY(1px);
}

.br-next {
  font-weight: bold;
  font-size: 14px;
}

@media only screen and (max-width: 1100px) {
  .base-recommendations-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .base-recommendations-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .base-recommendations-summary {
    margin: 10px 0;
  }

  .base-recommendations-container {
    padding: 20px;
  }

  .base-recommendations-item-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 550px) {

  .base-recommendations-layout {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
  }

  .br-footer {
    display: flex;
  }
}
</style>
