<template>
  <div v-if="prod != null">
    <div>
      <p class="perfIcon perfFlow" :class="getSize" v-if="prod.flowLowUS != null && prod.flowLowUS != 'NULL' && terms.flow != null">{{ $rt(terms.flow.mltText) }}: <b>{{ prod.flowLowUS
          }}-{{
            prod.flowHighUS }} gpm
        </b>({{ prod.flowLowM }}-{{ prod.flowHighM }} m3/hr)</p>
      <p class="perfIcon perfPressure" :class="getSize" v-if="prod.pressureLowUS != null && prod.pressureLowUS != 'NULL' && terms.pressure != null">{{ $rt(terms.pressure.mltText) }}: <b>{{
        prod.pressureLowUS }}-{{
            prod.pressureHighUS }} psi </b>({{ prod.pressureLowM }}-{{
            prod.pressureHighM }} bar)</p>
      <p class="perfIcon perfRadius" :class="getSize" v-if="prod.radiusLowUS != null && prod.radiusLowUS != 'NULL' && terms.radius != null">{{ $rt(terms.radius.mltText) }}: <b>{{
        prod.radiusLowUS }}-{{
            prod.radiusHighUS }} ft </b>({{ prod.radiusLowM }}-{{
            prod.radiusHighM }} m)</p>
      <p class="perfIcon perfDiameter" :class="getSize" v-if="prod.diameterLowUS != null && prod.diameterLowUS != 'NULL' && terms.diameter != null">{{ $rt(terms.diameter.mltText) }}: <b>{{
        prod.diameterLowUS }}-{{ prod.diameterHighUS }} ft</b>
        ({{ prod.diameterLowM }}-{{ prod.diameterHighM }} m)</p>
      <p class="perfIcon perfNozzlesize" :class="getSize" v-if="prod.nozzleSize != null && prod.nozzleSize != 'NULL' && terms.nozzleSize != null">{{ $rt(terms.nozzleSize.mltText) }}: <b>{{
        prod.nozzleSize }}</b></p>
      <p class="perfIcon perfTrajectory" :class="getSize" v-if="prod.trajectoryData != null && prod.trajectoryData != 'NULL' && terms.trajectory != null">{{ $rt(terms.trajectory.mltText) }}:
        <b>{{
          prod.trajectoryData }}</b>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'performanceData',
    'size'
  ],
  data () {
    return {
      perfData: null,
    };
  },
  mounted () {
    if (this.$props.performanceData.pID_fk || this.$props.performanceData.pID) {
      this.perfData = this.$props.performanceData
    } else {
      this.$props.performanceData
        .then((response) => {
          this.perfData = response;
        });
    }
  },
  computed: {
    prod () {
      if (this.perfData) {
        return this.perfData;
      }
    },
    terms () {
      const terms = this.$tm('extraTerms');
      var flowTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'flow';
        }
      });
      var pressureTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'pressure';
        }
      });
      var radiusTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'radius';
        }
      });
      var diameterTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'diameter';
        }
      });
      var nozzleSizeTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'nozzleSize';
        }
      });
      var trajectoryTerm = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'trajectory';
        }
      });

      return {
        flow: flowTerm,
        pressure: pressureTerm,
        radius: radiusTerm,
        diameter: diameterTerm,
        nozzleSize: nozzleSizeTerm,
        trajectory: trajectoryTerm
      }
    },
    getSize () {
      return (this.size === "small") ? "perfSmall" : "";
    }
  }

};
</script>
<style scoped>
  .perfSmall {
   font-size: 13px;
  }
</style>