<template>

  <ResourcesSearchResults :id="'resources'" v-show="true" :resources="getResources" :selected="null" :type="type"
    :attach="attach" />

</template>
<script>
import gsap from 'gsap';
import { useUtilityStore } from '~/store/UtilityStore';
import ResourcesSearchResults from "~/components/resources/ResourcesSearchResults.vue";


export default {
  mounted() {
    this.resources = this.getResources;
  },
  data() {
    return {
      resources: null
    };
  },
  props: [
    'items',
    'type',
    'attach',
    'productTitle'
  ],
  components: {
    ResourcesSearchResults
  },
  computed: {
    getHeader() {
      const mlVar = 'resources';
      let terms = this.$tm('extraTerms');
      if (terms.length != 0) {
        const header = terms.find((element) => {
          if (element.mlVar != null) {
            return this.$rt(element.mlVar) === mlVar
          }
        });
        return header;
      }
      else return '';
    },
    getResources() {
      let items = this.$props.items;
      if (this.$props.items != null) {
        const uStore = useUtilityStore();
        var resources = uStore.groupResourcesBy(items, 'libraryCategory', this.$rt);
        var entries = Object.entries(resources);
        entries.forEach((element) => {
          element.isShow = false;
        });
        return entries;
      }
    },
  },
  methods: {
    checkIsVideo(item) {
      if (item.videoURL === null) {
        return false;
      } else {
        if (this.$rt(item.videoURL) == 'NULL') {
          return false;
        } else {
          return true;
        }
      }
    },
    getPrefix(item) {
      const fileSplit = (this.$rt(item.filename)).split('.');
      const lengthIndex = fileSplit.length - 1;
      const filePrefix = fileSplit[lengthIndex];
      return filePrefix.toUpperCase();
    },
    getVideoUrl(resource) {
      if (resource.videoURL) {
        const video = this.$rt(resource.videoURL);
        const splitVideo = video.split('/');
        const videoId = splitVideo[3];
        const videoURL = 'https://youtube.com/embed/' + videoId;
        return videoURL;
      }
      else return '';
    },
    handleItemDropDown(item, index) {
      item.isShow = !item.isShow;
    },
    handleCopyResourceLink(item, index) {
      const id = '#product-resource-icon' + index;
      const copyId = '#product-resource-copy' + index;
      var filePath;
      if (item.videoURL != null) {
        filePath = this.$rt(item.videoURL);
      } else {
        filePath = 'https://nelsonirrigation.com/library/' + this.$rt(item.filename);
      }
      navigator.clipboard.writeText(filePath);
      item.isCopied = true;

      if (this.prevCopy != null) {
        gsap.to(this.prevCopy, {
          display: 'none',
          duration: '0.1'
        });
      }
      gsap.to(copyId, {
        display: 'block'
      });
      gsap.to(id, {
        color: '#F48B41'
      });
      this.prevCopy = copyId;

    },
    handleTypeColor(item) {
      const fileSplit = (this.$rt(item.filename)).split('.');
      const lengthIndex = fileSplit.length - 1;
      const filePrefix = fileSplit[lengthIndex];
      if (filePrefix.toUpperCase() === 'PDF') {
        return {
          'color': "#990000"
        };
      } else if (filePrefix.toUpperCase() === 'DWG') {
        return {
          'color': "#0000EE"
        };
      }
    }
  }
};
</script>
<style scoped>
.product-resources-chevron {
  transform: rotate(-90deg);
  opacity: .3;
}

.product-resources-title-box {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 13px 10px 13px 20px;
}

.pr-title {
  font-weight: bold;
  font-size: 14px;
}

.product-resources-box {
  /*    width: 100%;
    margin: 20px;*/
  border-top: 1px solid #e7e7e7;
  margin: 0px;
  width: 100%;
}

.product-resources-container {
  background: #fff;
  border: 1px solid #e7e7e7;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

/* .product-resources-container :hover {
  text-decoration: underline;
} */

.product-resources-container :hover {
  .product-resources-chevron {
    opacity: 1;
  }
}

.product-resource-text-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-resources-files {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #e7e7e7;
  margin: 1rem 0;
  text-decoration: none;
  border-radius: 2px;
  line-height: 1.4em;
}

.product-resource-svg {
  transform: scale(1.5);
  transform-origin: 100% 50%;
}

.product-resources-type {
  color: #900;
  font-size: 15px;
  font-weight: 900;
  padding: .75rem 1rem;
  margin: 8px 0;
  border-right: 1px solid #D3CDC2;
}

.product-resource-icon {
  display: flex;
  align-items: center;
  width: 30px;
}

.product-resources-text {
  font-size: 15px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  color: black;
}

.product-resources-text:hover {
  text-decoration: underline;
}

.product-resources-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}

.product-resource-icon-box {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  z-index: 99999;
}

.product-resources-copy {
  background-color: #3B3B3B;
  padding: 0 10px;
  border-radius: 15PX;
  color: #FFFFFF;
  font-size: 15PX;
  margin-right: 10px;
}

.product-resources-video {
  max-width: 120px;
  width: 100%;
}

.product-results {
  margin: 0px;
  width: 100%;
  max-width: 1200px;
  border: none;
  padding: 0;

}

@media only screen and (max-width: 1200px) {}

.product-resources-item {
  margin-left: 20px;
  margin-right: 20px;

}
</style>